import noUiSlider from 'nouislider'

class MiniForm {

    /**
     * Set options for form.
     */
    constructor(options) {
        this.container = document.getElementById(options.containerID)
        this.destination = options.destination
        this.onSubmit = options.onSubmit
        this.monthlyEstimation = options.monthlyEstimation === undefined ? true : options.monthlyEstimation
        this.inputFields = options.inputFields === undefined ? true : options.inputFields
        this.inputLabels = options.inputLabels
        this.acceptTerms = options.acceptTerms
        this.redirectDuplicate = options.redirectDuplicate
        this.redirectUnemployed = options.redirectUnemployed
        this.business = options.business === undefined ? false : options.business

        // Disable monthlyEstimation when business
        if (this.business) {
            this.monthlyEstimation = false
        }
    }

    /**
     * Init functions.
     */
    init() {
        this.form()
        this.cache()
        this.initSliders()
        this.listeners()
    }

    /**
     * Create the form.
     */
    form() {
        // Print input fields if wanted
        let inputFields =  (!this.inputFields) ? '' : `
            <div class="input-row">
                <div class="single-input">
                    <div>
                        ${this.inputLabels ? `<label for="etunimi">Etunimi</label>` : ''}
                        <input placeholder="Etunimi" name="etunimi" type="text" class="mf_input" required value="">
                    </div>
                </div><div class="single-input">
                    <div>
                        ${this.inputLabels ? `<label for="sukunimi">Sukunimi</label>` : ''}
                        <input placeholder="Sukunimi" name="sukunimi" type="text" class="mf_input" required value="">
                    </div>
                </div><div class="single-input">
                    <div>
                        ${this.inputLabels ? `<label for="email">Sähköpostiosoite</label>` : ''}
                        <input placeholder="Sähköpostiosoite" name="email" type="email" class="mf_input" required value="">
                    </div>
                </div><div class="single-input">
                    <div>
                        ${this.inputLabels ? `<label for="puhelin">Puhelinnumero</label>` : ''}
                        <input placeholder="Puhelinnumero" name="puhelin" type="text" class="mf_input" required value="">
                    </div>
                </div>
            </div>
        `;

        let acceptTerms = ( !this.acceptTerms ) ? '' : `<div class="terms-row"> <input id="acceptterms" type="checkbox" name="acceptterms" required> <label for="acceptterms"> <p> ${this.acceptTerms} </p> </label> </div>`
        let over250     = ( !this.business ) ? '' : `<div class="terms-row"> <input id="businessOver" type="checkbox" name="businessOver"> <label for="businessOver"> <p> Haluan hakea yli 250 000 € lainaa </p> </label> </div>`

        // Print the form
        this.container.innerHTML= `<form method="POST" action="${this.destination}${window.location.search}" class="miniform" id="miniform">
        <div class="slider-row">
            <div class="single-silder">
                <div class="slider-container">
                    <div class="slider-text">
                        <span>Lainan suuruus <span id="mf_amount" class="slider-text-value">16 000</span></span>
                    </div>
                    <div class="loan-slider">
                        <span class="icon mf_icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="minus-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-minus-circle fa-w-16 fa-lg"><path fill="currentColor" d="M140 284c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v32c0 6.6-5.4 12-12 12H140zm364-28c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z" class=""></path></svg>
                        </span><div id="mf_loanslider"></div><span class="icon mf_icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-plus-circle fa-w-16 fa-lg"><path fill="currentColor" d="M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z" class=""></path></svg>
                        </span>
                    </div>
                </div>
            </div><div class="single-silder">
                <div class="slider-container">
                    <div class="slider-text">
                        <span>Laina-aika <span id="mf_time" class="slider-text-value">8</span></span>
                    </div>
                    <div class="loan-slider">
                        <span class="icon mf_icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="minus-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-minus-circle fa-w-16 fa-lg"><path fill="currentColor" d="M140 284c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v32c0 6.6-5.4 12-12 12H140zm364-28c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z" class=""></path></svg>
                        </span><div id="mf_timeslider"></div><span class="icon mf_icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-plus-circle fa-w-16 fa-lg"><path fill="currentColor" d="M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z" class=""></path></svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        ${over250}
        ${
            this.monthlyEstimation ?
            `<div class="amount-row">
                <p> Lainan kuukausierän arvio*: <span id="monthly"></span>
            </div>`
            : ''
        }
        ${inputFields}
        <input type="hidden" name="maksuaika" id="mf_lainaaika" class="mf_input">
        <input type="hidden" name="luottoraja" id="mf_lainasumma" class="mf_input">
        ${acceptTerms}
        ${this.redirectDuplicate ? `<input type="hidden" name="data_redirect" id="mf_redirect" class="mf_input" value="${this.redirectDuplicate}">` : ''}
        ${this.redirectRate ? `<input type="hidden" name="data_rate" id="mf_rate" class="mf_input" value="${this.redirectRate}">` : ''}
        ${this.redirectUnemployed ? `<input type="hidden" name="data_unemployed" id="mf_unemployed" class="mf_input" value="${this.redirectUnemployed}">` : ''}
        <div class="button-container">
            <button type="submit" id="mf_submitbutton" class="button"><span>Kilpailuta lainat</span></button>
        </div>
        ${
            this.monthlyEstimation ?
            `<p id="interestRate"><sup>*</sup>Esimerkki lainakustannuksista: Nimelliskorko <span id="nominal"></span>%, Todellinen vuosikorko <span id="effective"></span>%, Tilinhoitomaksut 0€. Aloitus- ja kuukausikulut 0€, Korkokulut <span id="expenses"></span>€, Kokonaiskulut <span id="total"></span>€. Tarjottava laina-aika voi olla 1 - 15 vuoden välillä ja korko 4,5% - 20%.</p>`
            : ''
        }
    </form>`
    }

    /**
     * Load elements to cache.
     */
    cache() {
        this.loanslider = document.getElementById('mf_loanslider')
        this.timeslider = document.getElementById('mf_timeslider')

        this.amount = document.getElementById('mf_amount')
        this.time = document.getElementById('mf_time')

        this.lainasumma = document.getElementById('mf_lainasumma')
        this.lainaaika = document.getElementById('mf_lainaaika')

        this.adjust = document.getElementsByClassName('mf_icon')

        // Only calculate when monthly estimation is enabled
        if (this.monthlyEstimation) {
            this.monthlyValue = document.getElementById('monthly')
            this.nominalValue = document.getElementById('nominal')
            this.effectiveValue = document.getElementById('effective')
            this.expensesValue = document.getElementById('expenses')
            this.totalValue = document.getElementById('total')
        }

        this.form = document.getElementById('miniform')
        this.button = document.getElementById('mf_submitbutton')
    }

    /**
     * Create sliders.
     */
    initSliders() {
        noUiSlider.create(this.loanslider, {
            keyboardSupport: false,
            start: this.business ? 50000 : 15000,
            step: this.business ? 1000 : 100,
            connect: [true, false],
            range: {
                min: this.business ? 1000 : 500,
                max: this.business ? 250000 : 70000
            },
            format: {
                from(value) {
                    return Math.round(value)
                },
                to(value) {
                    return Math.round(value)
                }
            }
        })
        noUiSlider.create(this.timeslider, {
            keyboardSupport: false,
            start: this.business ? 12 : 8,
            step: 1,
            connect: [true, false],
            range: {
                min: 1,
                max: this.business ? 72 : 15
            },
            format: {
                from(value) {
                    return Math.round(value)
                },
                to(value) {
                    return Math.round(value)
                }
            }
        })

        // Update fields with slider value.
        this.loanslider.noUiSlider.on('update', () => {
            this.updateValue(this.loanslider.noUiSlider.get(), 'amount')
            // No need to calculateExpenses when business is enabled
            if (! this.business) {
                this.calculateExpenses(this.loanslider.noUiSlider.get(),this.timeslider.noUiSlider.get())
            }
        })

        // Update fields with slider value.
        this.timeslider.noUiSlider.on('update', () => {
            this.updateValue(this.timeslider.noUiSlider.get(), 'time')
            // No need to calculateExpenses when business is enabled
            if (! this.business) {
                this.calculateExpenses(this.loanslider.noUiSlider.get(),this.timeslider.noUiSlider.get())
            }
        })
    }

    /**
     * Init listeners.
     */
    listeners() {
        var parent = this
        // When submit button is clicked.
        this.button.addEventListener("click", function(e){
          // Prevent default submit but still enable basic html validations.
          e.preventDefault()
          parent.form.reportValidity()

          /**
           * If onSubmit is set, wait for true result. If not, then just submit the form.
           */
          if ( parent.form.checkValidity() ) {
            if ( typeof parent.onSubmit === "function" ) {
              parent.onSubmit( parent.getFields(), function () {
                parent.form.submit()
              })
            }
            else {
              parent.form.submit()
            }
          }
        })

        // Add listeners to + and - buttons for both sliders.
        this.adjust[0].addEventListener("click", (e) => {
            this.loanslider.noUiSlider.set( this.loanslider.noUiSlider.get() - ( this.business ? 1000 : 100 ) )
        })
        this.adjust[1].addEventListener("click", (e) => {
            this.loanslider.noUiSlider.set( this.loanslider.noUiSlider.get() + ( this.business ? 1000 : 100 ) )
        })
        this.adjust[2].addEventListener("click", (e) => {
            this.timeslider.noUiSlider.set( this.timeslider.noUiSlider.get() - 1 )
        })
        this.adjust[3].addEventListener("click", (e) => {
            this.timeslider.noUiSlider.set( this.timeslider.noUiSlider.get() + 1 )
        })

        // Add listener for "businessOver" checkbox.
        if ( this.business ) {
            document.getElementById('businessOver').addEventListener("change", function () {
                if (this.checked) {
                    parent.loanslider.noUiSlider.updateOptions( {
                        range: {
                            min: 251000,
                            max: 8000000
                        }
                      } )
                    parent.timeslider.noUiSlider.updateOptions( {
                        range: {
                            min: 3,
                            max: 72
                        }
                      } )
                } else {
                    parent.loanslider.noUiSlider.updateOptions( {
                        range: {
                            min: 1000,
                            max: 250000
                        }
                      } )
                    parent.timeslider.noUiSlider.updateOptions( {
                        range: {
                            min: 1,
                            max: 36
                        }
                      } )
                }
            })
        }
    }

    /**
     * Get all form fields.
     */
    getFields() {
      let fields = document.getElementsByClassName('mf_input')
      let data = {}
      for (var i = 0; i < fields.length; i++) {
        data[fields[i].name] = fields[i].value
      }
      return data
    }

    /**
     * Update field values.
     */
    updateValue(value, target) {
        if ( target == 'amount' ) {
            this.amount.innerHTML = this.spaceNumber(value) + ' €'
            this.lainasumma.value = value
        }
        else {
            this.time.innerHTML = value + (this.business ? ' kk' : ' vuotta')
            this.lainaaika.value = value
        }
    }
    calculateExpenses(loanamount, loanperiod) {
        // Return out of function when monthly estimation is not set
        if (!this.monthlyEstimation) {
            return;
        }

        const interestArray = [
            { min: 500, max: 4900, interest: { nominal: 10.4, effective: 10.91 } },
            { min: 5000, max: 9900, interest: { nominal: 9.5, effective: 9.92 } },
            { min: 10000, max: 14900, interest: { nominal: 8.75, effective: 9.11 } },
            { min: 15000, max: 19900, interest: { nominal: 7.75, effective: 8.03 } },
            { min: 20000, max: 24900, interest: { nominal: 6.85, effective: 7.07 } },
            { min: 25000, max: 29900, interest: { nominal: 6.45, effective: 6.64 } },
            { min: 30000, max: 34900, interest: { nominal: 6, effective: 6.17 } },
            { min: 35000, max: 39900, interest: { nominal: 5.45, effective: 5.59 } },
            { min: 40000, max: 50000, interest: { nominal: 5, effective: 5.12 } },
            { min: 50100, max: 70000, interest: { nominal: 4.5, effective: 4.59 } }
        ]

        let amount = Math.round(loanamount / 100) * 100
        if (amount < 500) amount = 500
        if (amount > 70000) amount = 70000
        let interest = interestArray.filter(interest =>
            amount >= interest.min && amount <= interest.max
        )[0].interest

        const nominal = parseFloat(interest.nominal / 100)
        const subvalue = parseFloat(Math.pow(1 + (nominal / 12), -loanperiod * 12))
        const result = amount * (nominal / 12 / (1 - subvalue))
        const total = result * (loanperiod * 12)
        const expenses = total - amount
        const monthly = String(isNaN(result) ? 0 : Math.round(result)).replace(/(\d*)(\d{3})$/, '$1 $2')
        const expensesString = String(isNaN(expenses) ? 0 : Math.round(expenses)).replace(/(\d*)(\d{3})$/, '$1 $2')
        const totalString = String(isNaN(total) ? 0 : Math.round(total)).replace(/(\d*)(\d{3})$/, '$1 $2')
        const nominalString = interest.nominal.toFixed(2).toString().replace('.', ',')
        const effective = interest.effective.toFixed(2).toString().replace('.', ',')

        this.monthlyValue.innerHTML = monthly + ' €/kk';
        this.expensesValue.innerHTML = expensesString;
        this.nominalValue.innerHTML = nominalString;
        this.effectiveValue.innerHTML = effective;
        this.totalValue.innerHTML = totalString;

    }
    /**
     * Add space in large numbers.
     */
    spaceNumber(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
}

export default MiniForm
